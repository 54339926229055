"use client";

import {
  Day,
  Inject,
  Month,
  ScheduleComponent,
  ViewDirective,
  ViewsDirective,
  Week,
} from "@syncfusion/ej2-react-schedule";
import { registerLicense } from "@syncfusion/ej2-base";
import "./Styles.css";
import React from "react";

// registerLicense(
//   "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCekx0RHxbf1x0ZFRHalhSTnVeUiweQnxTdEFjXX5acXVRRWNdUER1Xw=="
// );
registerLicense(
  "ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Xd0BjUXtZc3VcR2dY"
);

const data = [
  {
    Id: 1,
    Subject: "Trening Prsa",
    StartTime: new Date(2024, 0, 1, 12, 0),
    EndTime: new Date(2024, 0, 1, 12, 30),
    IsAllDay: false,
    RecurrenceRule: "FREQ=DAILY;BYDAY=MO,TH",
  },
  {
    Id: 2,
    Subject: "Trening Noge",
    StartTime: new Date(2024, 0, 1, 12, 0),
    EndTime: new Date(2024, 0, 1, 12, 30),
    IsAllDay: false,
    RecurrenceRule: "FREQ=DAILY;BYDAY=TU,FR",
  },
  {
    Id: 3,
    Subject: "Trening Trbuh",
    StartTime: new Date(2024, 0, 1, 12, 0),
    EndTime: new Date(2024, 0, 1, 12, 30),
    IsAllDay: false,
    RecurrenceRule: "FREQ=DAILY;BYDAY=WE,SA",
  },
  {
    Id: 4,
    Subject: "Spavanje 1",
    StartTime: new Date(2024, 0, 1, 1, 0),
    EndTime: new Date(2024, 0, 1, 6, 0),
    IsAllDay: false,
    RecurrenceRule: "FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR,SA,SU",
  },
  {
    Id: 5,
    Subject: "Krevet",
    StartTime: new Date(2024, 0, 1, 0, 0),
    EndTime: new Date(2024, 0, 1, 1, 0),
    IsAllDay: false,
    RecurrenceRule: "FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR,SA,SU",
  },
  {
    Id: 6,
    Subject: "Čaj",
    StartTime: new Date(2024, 0, 1, 6, 0),
    EndTime: new Date(2024, 0, 1, 6, 30),
    IsAllDay: false,
    RecurrenceRule: "FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR,SA",
  },
  {
    Id: 7,
    Subject: "Odmrzavanje",
    StartTime: new Date(2024, 0, 1, 6, 0),
    EndTime: new Date(2024, 0, 1, 6, 30),
    IsAllDay: false,
    RecurrenceRule: "FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR,SA",
  },
  {
    Id: 8,
    Subject: "Njemački",
    StartTime: new Date(2024, 0, 1, 6, 30),
    EndTime: new Date(2024, 0, 1, 7, 0),
    IsAllDay: false,
    RecurrenceRule: "FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR,SA",
  },
  {
    Id: 9,
    Subject: "Rad, obaveze",
    StartTime: new Date(2024, 0, 1, 7, 0),
    EndTime: new Date(2024, 0, 1, 9, 0),
    IsAllDay: false,
    RecurrenceRule: "FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR,SA",
  },
  {
    Id: 10,
    Subject: "Spremanje",
    StartTime: new Date(2024, 0, 1, 9, 15),
    EndTime: new Date(2024, 0, 1, 10, 0),
    IsAllDay: false,
    RecurrenceRule: "FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR,SA",
  },
  {
    Id: 11,
    Subject: "Smoothie",
    StartTime: new Date(2024, 0, 1, 10, 0),
    EndTime: new Date(2024, 0, 1, 11, 0),
    IsAllDay: false,
    RecurrenceRule: "FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR,SA,SU",
  },
  {
    Id: 12,
    Subject: "Rad, App",
    StartTime: new Date(2024, 0, 1, 11, 0),
    EndTime: new Date(2024, 0, 1, 12, 0),
    IsAllDay: false,
    RecurrenceRule: "FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR,SA",
  },
  {
    Id: 13,
    Subject: "Rad, Todo",
    StartTime: new Date(2024, 0, 1, 12, 30),
    EndTime: new Date(2024, 0, 1, 14, 0),
    IsAllDay: false,
    RecurrenceRule: "FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR,SA",
  },
  {
    Id: 14,
    Subject: "Spavanje 2",
    StartTime: new Date(2024, 0, 1, 14, 0),
    EndTime: new Date(2024, 0, 1, 16, 0),
    IsAllDay: false,
    RecurrenceRule: "FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR,SA,SU",
  },
  {
    Id: 15,
    Subject: "VItamini",
    StartTime: new Date(2024, 0, 1, 16, 0),
    EndTime: new Date(2024, 0, 1, 16, 30),
    IsAllDay: false,
    RecurrenceRule: "FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR,SA,SU",
  },
  {
    Id: 16,
    Subject: "Hana",
    StartTime: new Date(2024, 0, 1, 16, 30),
    EndTime: new Date(2024, 0, 1, 18, 0),
    IsAllDay: false,
    RecurrenceRule: "FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR,SA",
  },
  {
    Id: 17,
    Subject: "Jelo",
    StartTime: new Date(2024, 0, 1, 18, 0),
    EndTime: new Date(2024, 0, 1, 19, 0),
    IsAllDay: false,
    RecurrenceRule: "FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR,SA,SU",
  },
  {
    Id: 18,
    Subject: "Hana",
    StartTime: new Date(2024, 0, 1, 19, 0),
    EndTime: new Date(2024, 0, 1, 21, 0),
    IsAllDay: false,
    RecurrenceRule: "FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR,SA",
  },
  {
    Id: 19,
    Subject: "Rad",
    StartTime: new Date(2024, 0, 1, 21, 15),
    EndTime: new Date(2024, 0, 1, 23, 0),
    IsAllDay: false,
    RecurrenceRule: "FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR,SA",
  },
  {
    Id: 20,
    Subject: "Zabava",
    StartTime: new Date(2024, 0, 1, 23, 0),
    EndTime: new Date(2024, 0, 2, 0, 0),
    IsAllDay: false,
    RecurrenceRule: "FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR,SA",
  },
  {
    Id: 21,
    Subject: "Tuširanje",
    StartTime: new Date(2024, 0, 1, 22, 0),
    EndTime: new Date(2024, 0, 1, 23, 0),
    IsAllDay: false,
    RecurrenceRule: "FREQ=WEEKLY;BYDAY=SU",
  },
  {
    Id: 22,
    Subject: "Kuhanje",
    StartTime: new Date(2024, 0, 1, 16, 30),
    EndTime: new Date(2024, 0, 1, 18, 0),
    IsAllDay: false,
    RecurrenceRule: "FREQ=WEEKLY;BYDAY=SU",
  },
  {
    Id: 23,
    Subject: "Trčanje",
    StartTime: new Date(2024, 0, 1, 9, 0),
    EndTime: new Date(2024, 0, 1, 10, 0),
    IsAllDay: false,
    RecurrenceRule: "FREQ=WEEKLY;INTERVAL=2;BYDAY=SU",
  },
  {
    Id: 24,
    Subject: "Plivanje",
    StartTime: new Date(2024, 0, 14, 9, 0),
    EndTime: new Date(2024, 0, 14, 10, 0),
    IsAllDay: false,
    RecurrenceRule: "FREQ=WEEKLY;INTERVAL=2;BYDAY=SU",
  },
  {
    Id: 25,
    Subject: "Kupaona",
    StartTime: new Date(2024, 0, 14, 12, 0),
    EndTime: new Date(2024, 0, 14, 12, 30),
    IsAllDay: false,
    RecurrenceRule: "FREQ=WEEKLY;INTERVAL=2;BYDAY=SU",
  },
  {
    Id: 26,
    Subject: "Nokti ruke",
    StartTime: new Date(2024, 0, 14, 23, 0),
    EndTime: new Date(2024, 0, 14, 23, 15),
    IsAllDay: false,
    RecurrenceRule: "FREQ=WEEKLY;INTERVAL=2;BYDAY=SU",
  },
  {
    Id: 27,
    Subject: "Stopala",
    StartTime: new Date(2024, 0, 7, 23, 0),
    EndTime: new Date(2024, 0, 7, 23, 15),
    IsAllDay: false,
    RecurrenceRule: "FREQ=WEEKLY;INTERVAL=4;BYDAY=SU",
  },
  {
    Id: 28,
    Subject: "Zubi 1",
    StartTime: new Date(2024, 0, 1, 9, 0),
    EndTime: new Date(2024, 0, 1, 9, 15),
    IsAllDay: false,
    RecurrenceRule: "FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR,SA",
  },
  {
    Id: 29,
    Subject: "Zubi 2",
    StartTime: new Date(2024, 0, 1, 21, 0),
    EndTime: new Date(2024, 0, 1, 21, 15),
    IsAllDay: false,
    RecurrenceRule: "FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR,SA",
  },
  {
    Id: 30,
    Subject: "Zubi 1",
    StartTime: new Date(2024, 0, 1, 8, 45),
    EndTime: new Date(2024, 0, 1, 9, 0),
    IsAllDay: false,
    RecurrenceRule: "FREQ=DAILY;BYDAY=SU",
  },
  {
    Id: 31,
    Subject: "Zubi 2",
    StartTime: new Date(2024, 0, 1, 21, 45),
    EndTime: new Date(2024, 0, 1, 22, 0),
    IsAllDay: false,
    RecurrenceRule: "FREQ=DAILY;BYDAY=SU",
  },
  {
    Id: 32,
    Subject: "Kosa",
    StartTime: new Date(2024, 5, 21, 0, 0),
    EndTime: new Date(2024, 5, 21, 24, 0),
    IsAllDay: true,
    RecurrenceRule: "FREQ=YEARLY",
  },
  {
    Id: 33,
    Subject: "Brada",
    StartTime: new Date(2024, 11, 21, 0, 0),
    EndTime: new Date(2024, 11, 21, 24, 0),
    IsAllDay: true,
    RecurrenceRule: "FREQ=YEARLY",
  },
];

// TODO Prebaci u mongodb

function Todo() {
  return (
    <div className="centered-div">
      <ScheduleComponent
        firstDayOfWeek={1}
        eventSettings={{
          dataSource: data,
        }}
        selectedDate={new Date()}
        currentView="Week"
      >
        <ViewsDirective>
          <ViewDirective option="Day" />
          <ViewDirective option="Week" />
          <ViewDirective option="Month" />
        </ViewsDirective>

        <Inject services={[Day, Week, Month]} />
      </ScheduleComponent>
      <div className="schedule-dock"></div>
    </div>
  );
}

export default Todo;
