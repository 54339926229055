import http from "../http-common";

class IngredientsDataService {
  getArray() {
    return http.get(`/ingredients`);
  }

  setArray(arr) {
    return http.post(`/postIngredients?array=${JSON.stringify(arr)}`);
  }
}

export default new IngredientsDataService();
